import * as React from "react"

import { Seo, Layout } from "../components"

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <h1 className="title">404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
